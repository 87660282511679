import React, { useState } from 'react';
import './Service.css';
import ServiceImg from './Resources/Image/service.jpg';
import CaseReview from './Resources/Image/caseReview.jpg';
import MedicalBoard from './Resources/Image/medicalBoard.jpg';
import SecondOpinion from './Resources/Image/secondOpinion.png'; 
import ServicePopUp from './ServicePopUp'; 
import { Grid } from 'semantic-ui-react';

// const breakPoints = [
//   { width: 1, itemsToShow: 1 },
//   { width: 550, itemsToShow: 2, itemsToScroll: 2 },
//   { width: 768, itemsToShow: 3 },
//   { width: 1200, itemsToShow: 3 }
// ];
const FakeServices = [
  {
    Id: 1,
    Title: "Case Review",
    Profile: CaseReview,
    Details: "You can find out if the standard or proper treatment is going on from the case reviews of our expert reviewers. Within 48 hours of providing the necessary information, the case review of our International Reviewer Panel will confirm your medical procedure.",

  },
  {
    Id: 2,
    Title: "Second Opinion",
    Profile: SecondOpinion,
    Details: "SureCares’ member can ask for a Second opinion from any of SureCare doctor on any ongoing treatment taken in Bangladesh. According to SureCare service policy the patient will get a complete second opinion after a virtual meeting with the SureCare assigned doctor.    A virtual second opinion from SureCare gives you a convenient, secure and timely way to get answers to your questions about a diagnosis or treatment plan you are going through. A second opinion can help you make an informed, confident decision about your medical care. Sometimes, you may find out about treatment options you didn’t know were available. And in some cases, the information you gain during a second opinion consultation can even change your diagnosis. SureCares’ Second Opinion service connects you to an expert physician who specializes in your specific health needs analysing your current health condition. SureCares’ doctors are internationally renowned and experienced. You can be assured that you’re receiving an expert second opinion you can rely on.    I hereby certify that I am at least 18 years of age and am qualified under the laws of my state to make medical decisions on my own behalf. I acknowledge that my ability to access and use the Company's system and information is conditional upon the truthfulness of my certification of age.    ",
  },
  {
    Id: 3,
    Title: "Medical Board",
    Profile: MedicalBoard,
    Details: "If there is a need to form a medical board for outgoing hospitalized critical patients, you can choose from our panel of doctors one or more international standard expert physicians. Sure Care Doctor will be added to the desired medical board in time if you go to the medical board option and give the necessary information.",
  },
];

const Cards = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState();
  function openModal(e) {
    setData(e.data);
    setIsOpen(true);

  }
  function closeModal() {
    setIsOpen(false);
  }


  return (
    <div id="service">
      <div className='restContainer' >
        <div className='carouselMainText'>
          <h1><b>Our Special Services</b></h1> 
        </div>

          <Grid stackable columns={3} style={{ justifyContent: "center"}}className='gridContainer'>
          {FakeServices.map((data, idx) =>

            <Grid.Column item key={idx} style={{ display: "flex", justifyContent: "center" }}>
              <div className="card transition" style={{zIndex: '0'}}>
                <img src={data.Profile} alt='image' style={{ width: "100%", height:"50%" }} />
                <h2 className="transition">{data.Title}</h2>
                <p>{data.Details}</p> <button className="cta" onClick={() => openModal({ data })}> Learn more </button>
                <div className="cta-container transition"></div>
                <div className="card_circle transition"></div>
              </div>
            </Grid.Column>
          )}
        </Grid>
        <ServicePopUp modalIsOpen={modalIsOpen} closeModal={closeModal} data={data}></ServicePopUp>

      </div>
    </div>

  );
}

export default Cards;
