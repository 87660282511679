import React, { useState } from 'react';
import { Form, Input, TextArea, Button, Grid } from 'semantic-ui-react';
import { IoIosSend } from 'react-icons/io' 
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

const ContactUs = () => {


    const [message, setMessage] = useState({});
    const handleMessage = (e) => {
        const newMessage = { ...message }
        newMessage[e.target.name] = e.target.value;
        setMessage(newMessage);
    }
    const [showToaster, setShowToaster] = useState();
    const notifySuccess = () => toast.success('Message Sent.');
    const notifyUnsuccess = () => toast.error('Message Sent.');
    const handleMessageForm = async (e) => {
        e.preventDefault();
        try {
            emailjs.sendForm('service_p2fts3v', 'template_16ukd8k', e.target, 'user_opkMswdya6Ssck96lKq6F')
            // .then((result) => {
            //     console.log(result.text);
            // }, (error) => {
            //     console.log(error.text);
            // });
            setShowToaster(notifySuccess());
        }

        catch (error) {
            console.log(error);
            setShowToaster(notifyUnsuccess());
        };
        e.target.reset();
    }





    return (
        <div className='contactUs' >
        <Grid container columns={1}>


            <div>
                <h1 style={{ color: '#c9116d' }}>Get In Touch</h1>
                <p style={{ color: '#232323' }}>You are always welcome to Sure Care</p>
            </div>
            <Grid.Column p="20px 0" m="auto">
                <Form onSubmit={handleMessageForm} className='contactForm'>
                    <Form.Field >
                        <label>Name</label>
                        <Input required
                            className='formInput'
                            width={12}
                            onChange={handleMessage}
                            name='name'
                            placeholder='Name' />
                    </Form.Field>
                    <Form.Group >

                        <Form.Field width={12} >
                            <label>Email</label>
                            <Input required
                                style={{ margin: "auto" }}
                                className='formInput'
                                onChange={handleMessage}
                                type='email'
                                name='email'
                                placeholder='Email' />

                        </Form.Field>
                        <Form.Field width={12}>
                            <label>Phone Number</label>
                            <Input required
                                style={{ margin: "auto" }}
                                className='formInput'
                                onChange={handleMessage}
                                name='phoneNumber'
                                placeholder='Phone Number' />
                        </Form.Field>
                    </Form.Group>



                    <Form.Field >
                        <label>Message</label>
                        <TextArea required
                            width={12}
                            style={{ margin: "auto" }}
                            className='formInput'
                            onChange={handleMessage}
                            name='message'
                            label='Message'
                            placeholder='Message...' />
                    </Form.Field>


                    <Form.Field control={Button} type='submit' style={{ color: 'white', background: "#c9116d" }}>Send Message  <IoIosSend /></Form.Field>

                </Form>
                <Toaster
                    position="bottom-left"
                    reverseOrder={false}
                />

            </Grid.Column>
        </Grid>
    </div>
    );
};

export default ContactUs;