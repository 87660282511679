import React from 'react';
import Hospital_1 from './Resources/Image/DAOB.png';
import Hospital_2 from './Resources/Image/HeartFoundation.png';
import Hospital_3 from './Resources/Image/hospital_3.png';
import Carousel from "react-elastic-carousel";


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 }
];



const Partner = () => {
    return (
        <div id="partner">
            <div className="restContainer" >
                <div className='carouselMainText'>
                    <h1><b>Our Affiliated Hospitals</b></h1>
                    {/* <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p> */}
                </div>
                <Carousel breakPoints={breakPoints}>


                    <div className='cardContainer'>

                        <div className='cardsItem'>
                            <figure className='partnerFigure'>
                                <img src={Hospital_1} alt='Squire Hospital' />
                            </figure>
                           
                        </div>
                    </div>
                    <div className='cardContainer'>

                        <div className='cardsItem'>
                            <figure className='partnerFigure'>
                                <img src={Hospital_2} alt='Lab Aid' />
                            </figure>
                            
                        </div>
                    </div>
                    <div className='cardContainer'>
                        <div className='cardsItem'>
                            <figure className='partnerFigure'>
                                <img src={Hospital_3} alt='Imperial Hospital' />
                            </figure>
                            
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default Partner;