import React, { useState } from 'react'
import Logo from './Resources/Image/sureCareLogo.svg';
import GooglePlay from './Resources/Image/googlePlay.svg';
import AppleStore from './Resources/Image/appleStore.svg';
import Visa from './Resources/Image/visa.svg';
import MasterCard from './Resources/Image/mastercard.svg';
import Bkash from './Resources/Image/bkash.svg';
import Nogod from './Resources/Image/nogod.svg';
import Upay from './Resources/Image/upay.svg';
import NexusPay from './Resources/Image/nexus.png';
import IslamiBank from './Resources/Image/mcash.png';
import Rocket from './Resources/Image/rocket.png';
import SSLBanner from './Resources/Image/SSLCommerz-Logo3.png';
import responsiveSSLBanner from './Resources/Image/sslCommerz.png';
import ServicePopUp from './ServicePopUp';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'
import { Grid } from 'semantic-ui-react';


const SSLCommerz = [
    {
        image: Visa
    },
    {
        image: MasterCard
    },
    {
        image: Bkash
    },
    {
        image: Nogod
    },
    {
        image: Upay
    },
    {
        image: NexusPay
    },
    {
        image: IslamiBank
    },
    {
        image: Rocket
    }
]



const Footer = () => {

    let myDate = new Date();

    let year = myDate.getFullYear();

    const [modalIsOpen, setIsOpen] = useState(false);
    const [data, setData] = useState()
    const openModal = (e) => {
        setIsOpen(true);
        setData(e)
    }
    function closeModal() {
        setIsOpen(false);
    }



    return (
        <div className='footer'>


            <ServicePopUp modalIsOpen={modalIsOpen} closeModal={closeModal} data={data}></ServicePopUp>
            <div className="copyright">

                <Grid doubling columns={4} className='footerTwo'>
                    <Grid.Column>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: "space-between", alignItems: "flex-start" }}>
                            <h3>Policies</h3>
                            <Link to={'/termscondition'} className='termsCondition'>
                                Terms and Conditions
                            </Link>
                            <Link to='/privacypolicy' className='termsCondition'>
                                Privacy and Policy
                            </Link>
                            <Link to={'/paymentrefundpolicy'} className='termsCondition'>
                                Payment & Return Policy
                            </Link>
                        </div>
                    </Grid.Column>

                    <Grid.Column style={{ textAlign: "center" }}>
                        <div style={{ textAlign: "start" }}>
                            <h3>Need Help ?</h3>

                            <p>
                                We are Always here for you! Knock us on Messenger anytime or Call our Hotline (10AM - 10PM).
                            </p>

                        </div>
                    </Grid.Column >
                    <Grid.Column style={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
                        <a target='_blank' href='https://play.google.com/store/apps/dev?id=8055689850553458617' style={{ margin: "auto" }}>
                            {/* <FaGooglePlay color='#4c6ef5' size='20px'/> Google Play Store */}
                            <img src={GooglePlay} alt='Google Play' className='appLinks' />
                        </a>

                        <a target='_blank' href='https://apps.apple.com/us/app/sure-care-services/id1606152939' style={{ margin: "auto" }}>
                            {/* <FaApple color='#666666' size='20px'/> Google Play Store */}
                            <img src={AppleStore} alt='Apple Store' className='appLinks' />
                        </a>

                    </Grid.Column>
                    <Grid.Column>
                        <div>
                            <h3>Contact Info</h3>

                            <b>Trade License : </b><i>245168</i>
                            <small>
                                <br />
                                House -8/7, Road no-01,
                                <br />
                                Block-D, Section- 15, Mirpur,
                                <br />
                                Dhaka-1216, Bangladesh
                                <br />
                                Phone: +880 1713504255
                                <br />
                                Email: info@surecare.services
                            </small>

                        </div>
                    </Grid.Column>

                </Grid>
                <Grid columns={1}>

                    <Grid.Column>
                        {/* <h3>Payment With</h3>
                        {
                            SSLCommerz.map((data, idx) => <img src={data.image} key={idx} style={{ height: '40px', minWidth: '51px', borderRadius: '5px' }} />)
                        }
                        <p style={{ paddingTop: "8px" }}> by SSLCOMMERZ</p> */}
                        <img src={SSLBanner} alt='' className='sslCommerz' />
                        <img src={responsiveSSLBanner} alt='' className='responsiveSslCommerz' />
                    </Grid.Column>

                </Grid>

                <Grid doubling columns={4} style={{ borderTop: "1px solid #dbdde0", margin: 'auto' }}>
                    <Grid.Column style={{ textAlign: "start", padding: "0", marginLeft: '0' }}>
                        <img src={Logo} alt='logo' style={{ width: "100px" }} />

                    </Grid.Column>

                    <Grid.Column style={{ textAlign: "end", padding: "0", marginRight: '0' }}>
                        <p><small>SURE CARE © {year} / ALL RIGHTS RESERVED</small> </p>
                    </Grid.Column >
                </Grid>
            </div>
        </div >
    );
};

export default Footer;