import React from 'react';
import Modal from 'react-modal';
const ServicePopUp = ({ modalIsOpen, closeModal, data }) => {

  const customStyles = {
    overlay: {
      background: '#000000cc',
      zIndex: "9999"
    },
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      minHeight: 'auto',
      maxHeight: '80%',
      width: '60%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'justify',
    },
  };

console.log(data);
  return (


    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={1}
        style={customStyles}
      >
        {data ? <div>
          <h2><b>{data.Title}</b></h2>
          <div>
            <p>{data.Details}</p>
          </div>
        </div> : <div>
          
        </div>}

        {/* {data} */}
          <button className='btn' style={{float:"right"}} onClick={closeModal}>Okay, got it</button>
      </Modal>
    </>
  );
};

export default ServicePopUp;