import React, { useRef, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

const Background = styled.div`
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index:5;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  
`;

const ModalWrapper = styled.div`
   {width: 60%;
  height: 50%;
  margin:auto;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 1023px)  {
    width: 80%;
    height: 400px;
    margin:auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 5px;
    overflow: hidden;
 }
 @media (max-width: 700px)  {
    width: 80%;
    height: 400px;
    margin:auto;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 10;
    border-radius: 5px;
    overflow: hidden;
 }
`;

const ModalImg = styled.img`
  {width: 100%;
  height: 100%;
  border-radius: 5px 0 0 5px;
  background: #000;}

  @media (max-width: 700px)  {
    width: 100%;
    height: 200px;
    border-radius: 5px 0 0 5px;
    background: #000;
  }
`;

const ModalContent = styled.div`
   {
        height: 400px;
    padding: 10px 30px;
    overflow: overlay;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    line-height: 1.8;
    color: #141414;
    justify-content: flex-start;
    h5{
        padding-right: 20px;
        font-weight:bold;
    }
    h6{
        font-weight: bold;
        font-size: 18px;
        padding: 10px 0;
    }
    p {
    margin-bottom: 1rem;
     }
    button {
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: none;
  }
  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
    }
    

`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
  color:red;
`;

const ProjectModal = ({ showModal, setShowModal, cardData }) => {
    const modalRef = useRef();

    const animation = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(-100%)`
    });

    const closeModal = e => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };

    const keyPress = useCallback(
        e => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
                console.log('I pressed');
            }
        },
        [setShowModal, showModal]
    );

    useEffect(
        () => {
            document.addEventListener('keydown', keyPress);
            return () => document.removeEventListener('keydown', keyPress);
        },
        [keyPress]
    );

    return (
        <>
            {showModal ? (
                <Background onClick={closeModal} ref={modalRef}>
                    <animated.div style={animation}>
                        <ModalWrapper showModal={showModal}>
                            <ModalImg src={cardData.Profile} alt={cardData.Name} />
                            <ModalContent>
                                <h2><b>{cardData.Name.toUpperCase()}</b></h2>
                                <h6>{cardData.Designation}</h6>
                                <p>{cardData.Details}</p>
                                {/* <button> get App</button> */}

                            </ModalContent>
                            <CloseModalButton
                                aria-label='Close modal'
                                onClick={() => setShowModal(prev => !prev)}
                            />
                        </ModalWrapper>
                    </animated.div>
                </Background>
            ) : null}
        </>
    );
};

export default ProjectModal;