import React from 'react';

export const PaymentRefundPolicy = () => {
    window.scrollTo(0, 0);

    return (
        <div className='condition'>
            <h2>PAYMENTS TERMS AND CONDITION OF SURE CARE</h2>
            <h3>Payments:</h3>
            <ol>
                <li>Sure Care shall solely handle all financial transactions against the Services.</li>
                <li>No payments for the Services will be made other than on Sure Care’s payment gateway.</li>
                <li>Where there is consultation fee, the Patients must pay the consultation fee and VAT before joining the doctor queue for consultation.</li>
                <li>Where there is no consultation fee, the Patients will not be required to make any payment and they will be directly transferred to the doctor consultation queue.</li>
                <li>Once the Patient has made the payment and joined the queue, s/he will not be able to cancel the payment except as provided herein.</li>
                <li>If the payment is successful, you will get a payment confirmation via text message/email.</li>
            </ol>
            <h3>Refund and cancellation:</h3>
            <ol>
                <li>If the wait time in the queue is more than 60 minutes, customers can cancel the consultation and a refund will be made deducting a small payment processing fee.</li>
                <li>If a doctor can not consult a Patient due to any reason within 60 minutes of Initiation Request, the user will get a full refund.</li>
                <li>If a Patient cancels a consultation prior to the Session or video call of the Doctor after acceptance by the Doctor of the Patient’s request before the expiry of 60 minutes from the Initiation Request, a 5% fee will be deducted and the remaining fee will be refunded to the Patient. There may be additional charges to make the refund. For example, if the user wants the money to be refunded to bKash or mobile banking system, bKash transaction fee will be deducted.</li>
                <li>For any cancellation and refund please email to: <b><i><u>refund@surecare.com</u></i></b></li>
                <li>Refund process may take up to 30 working days.</li>
            </ol>
            <h3>Declaration:</h3>
            I fully read and understand the above policy in connection with online payment of fees. I shall abide by the terms and conditions in force or modified from time to time pertaining to the payment.<br />
            <b><i> I accept all Terms and Conditions.</i></b>

        </div>
    )
}
