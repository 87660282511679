import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import './Navbar.css';
import Logo from './Resources/Image/sure_care_logo.svg';



const Navber = () => {

  const [showIcon, setShowIcon] = useState(false)

  const handleClick = () => {
    setShowIcon(!showIcon)
  }

  return (
    <>

      <nav className="navContainer ">
        <div className='navItems'>
          <img src={Logo} alt='Sure Care' className='navbarLogo' onClick={() => window.location.replace("/#home")} />
          <div className="menuIcon" onClick={handleClick}>
            <i className={showIcon ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
          <Nav className="me-auto"></Nav>
          <ul className={showIcon ? 'navMenu active' : 'navMenu'} onClick={handleClick}>

            <li><button onClick={() => window.location.replace("/#service")} className='navLinks '>Service</button> </li>
            <li><button onClick={() => window.location.replace("/#physicians")} className='navLinks '>Physicians</button></li>
            <li> <button onClick={() => window.location.replace("/#about")} className='navLinks '>About Us</button> </li>
            <li><button onClick={() => window.location.replace("/#partner")} className='navLinks '>Partner</button></li>
            <li><button onClick={() => window.location.assign("https://app.surecare.services/")} className='navLinks signBtn'>Login</button></li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navber;