import React from 'react';
import { Grid } from 'semantic-ui-react';
import AboutImg from '../Resources/Image/about-img.png';

const About = () => {
    return (
        <div id="about">
            <div className='restContainer'>
                <Grid doubling columns={2} className='aboutRow'>
                    <Grid.Column style={{display:"flex"}}>
                        <div className='aboutText'>
                            <h1> <b >About Us</b > </h1>
                            <p>You must be tense about your family's health. Where can treatment be taken? Need to think once more? Sure Care has always been a shadow of a friend in your healthcare. A group of International Specialized Physicians works as a reviewer. Sure Care wants to make sure you get the right treatment. Sure Care's service portfolio includes fitness tracking, case reviews, second opinions, medical boards, health guidelines, and EMI facilities.</p>
                            <button className="text-decoration-none btn" onClick={() => { window.location.assign("https://app.surecare.services/") }} >Get Started</button>
                        </div>
                    </Grid.Column>
                    <Grid.Column stretched>
                        <img src={AboutImg} alt='About Us' className='aboutImg' />

                    </Grid.Column>
                </Grid>
            </div>
        </div>

    );
};

export default About;