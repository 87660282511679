import React from 'react';
import '../App.css';
import TopBanner from './Resources/Image/TopBanner.png';
import './Intro.css';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Logo from './Resources/Image/sure_care_logo.svg';


const IntroSection = () => {
  return (
    <div id="home">
      <div className='introContainer'>
        <img src={TopBanner} alt='Top Banner' className="topBanner" />
        <div className='introText'>
          {/* <img src={Logo} alt='' style={{width: '150px', paddingBottom: '20px'}} /> */}
          <h2>Sure <span>Care</span></h2>
          <span>
            <h1>Providing an Exceptional Pateint Experince</h1>
            <p>SureCare is an innovative responsive medical services platform that provides medical advisory services to its members. The platform is composed of a network of national and international doctors and practitioners as well as medical service providers in Bangladesh.</p>
            <div>
              <button onClick={() => window.location.replace("/#service")} className='btn'>View Service</button>
              <PlayCircleOutlineIcon fontSize="large" color='secondary' className='intro_icon' />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}

export default IntroSection;
