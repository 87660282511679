import React from 'react'

const PrivacyPolicy = () => {

    window.scrollTo(0, 0);

    return (
        <div className='condition'>
            <h4> Privacy Policy</h4>
            <p>Sure Care built the Sure Care app as a Free app. This SERVICE is provided by Sure Care at no cost and is intended for use as is.</p>
            <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
            <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
            <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Sure Care unless otherwise defined in this Privacy Policy.</p>
            <h4>Information Collection and Use</h4>
            <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.</p>
            <p>The app does use third-party services that may collect information used to identify you.</p>
            <p>Link to the privacy policy of third-party service providers used by the app</p>
            <ul>
                <li>
                    <a target='_blank' href='https://policies.google.com/privacy'>Google Play Services</a>
                </li>
            </ul>
            <h4>Log Data</h4>
            <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.            </p>
            <h4>                Cookies            </h4>
            <p>                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.            </p>

            <p>                This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.            </p>
            <h4>                Service Providers            </h4>
            <p>                We may employ third-party companies and individuals due to the following reasons:            </p>
            <ul>
                <li>                    To facilitate our Service;                </li>
                <li>                    To provide the Service on our behalf;                </li>
                <li>                    To perform Service-related services; or                </li>
                <li>                    To assist us in analyzing how our Service is used.                </li>
            </ul>
            <p>                We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.            </p>
            <h4>               Security            </h4>
            <p>                We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.            </p>
            <h4>                Links to Other Sites            </h4>
            <p>                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.            </p>
            <h4>                Children’s Privacy            </h4>
            <p>                These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.            </p>
            <h4>                Changes to This Privacy Policy            </h4>
            <p>                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.            </p>
            <p>                This policy is effective as of 1st December 2021            </p>
            <h4>                Contact Us            </h4>
            <p>                If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact our support team for help.            </p>
            {/* <h2>Privacy and Policy</h2>
            <h4> Privacy:</h4>
            We take your personal privacy seriously. In addition to the publicly accessible this App or pages on this Website, there are sections on the site where you can request more information or submit personal information to be considered for a specific employment opportunity with the organization.
            For information about how we secure personal information, please read Security below. For information about how we use and secure registered users’ information as well as your rights and responsibilities regarding the use of these features, please read our Application Privacy Policy which is incorporated by reference into this Agreement.

            <h4> Use License: </h4>
            No permission is granted to temporarily or permanently download or use or make any copy of the materials (information or software) on Sure Cares’ Application for personal or any other form of usage. By accessing the information on the Application, you agree to not:
            1. Modify or copy the materials;
            2. use the materials for any commercial purpose or for any public display (commercial or non-commercial);
            3. attempt to decompile or reverse engineer any software contained on Sure Cares’ Application and website;
            4. remove any copyright or other proprietary notations from the materials; or
            5. transfer the materials to another person or “mirror” the materials on any other server.

            <h4> Membership:</h4>
            <ol>
                <li>Sure Care Application is the flagship healthcare Application in Bangladesh.</li>
                <li>Sure Care Application is the property of Sure Care Organization and will be the custodian of all information pertaining to Sure Care including the member details.</li>
                <li>Sure Care Application is a Health Care Program and it covers all the Health Issues.</li>
                <li>Sure Care will continue until terminated, suspended, modified or converted to other programs by Sure Care authority.</li>
                <li>Sure Care may, at its sole discretion and without any prior intimation, either add or discontinue any Health Centres/ Stores from the Sure Care from time to time.</li>
                <li>The Sure Care Program is open to citizens of Bangladesh above 18 years of age with a valid Bangladeshi mobile number only.</li>
                <li>Any individual who has a valid and active Bangladeshi mobile number and has registered or transacted at Sure Care is eligible for membership. Presently, no enrolment fee is applicable for membership of the Sure Care Application.</li>
                <li>Members will be auto-enrolled into the program on fulfilling the eligibility threshold (minimum spend). Every member has the option to opt-out of the program upon enrolment.</li>
                <li>The membership is valid subject to the member adhering to the Terms and Conditions of the Sure Care Program as published from time to time.</li>
                <li> Sure Care Membership is non-transferable.</li>
                <li>On consent, the member hereby agrees to receive all Marketing/ Promotional/ Transaction related communication from Sure Care Organizations and its affiliates through multiple modes of communication channels such as Email, SMS and others.</li>
                <li>As a precondition to becoming a member, a customer will be required to provide mandatory information including full name, valid & active Bangladeshi mobile number and adhere to such terms and conditions as may be prescribed for membership from time to time.</li>
                <li>The Sure Care membership will be issued solely at the discretion of the management and the final discretion on all matters relating to the membership shall rest with Sure Care.</li>
                <li>Sure Care members will not be entitled to any of the benefits under the Sure Care Program for any transaction of the nature of institutional sales.</li>
                <li>Sure Care membership is for individuals only and is not a corporate membership.</li>
                <li>Sure Care membership can be revoked or refused if a member is involved in any act of fraud, shoplifting, cheating with or without cause and without notice.</li>
                <li>Sure Care reserves the right to add, alter, amend and revise terms and conditions as well as rules and regulations governing the Sure Care membership without prior notice.</li>
            </ol>
            In case Sure Care Membership details are lost or misused by the Member, Sure Care shall not be responsible or liable for any misuse of such details. */}
        </div>
    )
}

export default PrivacyPolicy;