import Home from "./Components/Page/Home";
import Navbar from "./Components/Navbar";
import Service from "./Components//Service";
import About from "./Components/Page/About";
import './App.css';
import Footer from './Components/Footer';
import Partner from "./Components/Partner";
import {
  BrowserRouter as
    Router,
  Switch,
  Route,
} from 'react-router-dom'; 
import { TermsCondition } from "./Components/Page/TermsConditions";
import { PaymentRefundPolicy } from "./Components/Page/PaymentRefundPolicy";
import PrivacyPolicy from "./Components/Page/PrivacyPolicy";

function App() {
  return (

    <Router>
      <Navbar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/service' component={Service} />
        <Route path='/partner' component={Partner} />
        <Route path='/privacypolicy' component={PrivacyPolicy} />
        <Route path='/termscondition' component={TermsCondition} />
        <Route path='/paymentrefundpolicy' component={PaymentRefundPolicy} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
