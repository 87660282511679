import React from 'react';
import Intro from '../Intro';
import Service from '../Service';
import About from './About';
import Choose from '../Choose';
import Partner from '../Partner';
import Doctors from '../Doctors';
import ContactUs from '../ContactUs';
import CookieConsent from "react-cookie-consent";


const Home = () => {
    return (
        <>
            <Intro />
            <div className='home'>
            <Service />
            <Doctors />
            <About />
            <Choose />
            <Partner />
           <ContactUs />
            </div>
            <CookieConsent  location="bottom"
  buttonText="I Understand"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}>We are asking our website visitors to consent to the use of cookies by
Sure Care to continue to our website. We use cookies to enhance your
site experience and for analytics purposes.</CookieConsent>
        </>
    );
};

export default Home;