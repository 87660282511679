import React from 'react';

export const TermsCondition = () => {

    window.scrollTo(0, 0);
    return (

        <div className='condition'>
            <h2>Terms and Conditions</h2>

            <h3>  Terms of use:</h3>
            Please carefully go through these terms and conditions before you decide to access the Application or avail the services made available by Sure Care. These Terms and the Privacy Policy together constitute a legal agreement between you and Sure Care in connection with your visit to the Application.
            This Agreement applies to all services made available by Sure Care on the Application.
            The Services may change from time to time, at the sole discretion of Sure Care, and the agreement will apply to your visit to and your use of the Application to avail the Service, as well as to all information provided by you on the App at any given point in time.
            This Agreement defines the terms and conditions under which you are allowed to use the Website and describes the manner in which we shall treat your account while you are registered as a member with us. If you have any questions about any part of the Agreement, feel free to contact us at….. Email…. address.
            By accessing the Application or Website to use the Services, you irrevocably accept all the terms and conditions stipulated in this Agreement and agree to fully abide by them.
            By availing any Service, you signify your agreement and acceptance to this Agreement.
            We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time.
            You acknowledge that you will be bound by this Agreement for availing any of the Services offered by us. If you do not agree with any part of the Agreement, please do not use the Application or Website and do not avail any Services.


            <h3>  Conditions of use:</h3>
            You must be 18 years of age or older to register, use the Services, or visit or use the Application in any manner. By registering, visiting and using the Application or accepting this Agreement, you represent and warrant to Sure Care that you are 18 years of age or older, and that you have the right, authority and capacity to use the Application and the Services available through the Application or Website, and agree to and abide by this Agreement.

 <h2>Privacy and Policy</h2>
            <h4> Privacy:</h4>
            We take your personal privacy seriously. In addition to the publicly accessible this App or pages on this Website, there are sections on the site where you can request more information or submit personal information to be considered for a specific employment opportunity with the organization.
            For information about how we secure personal information, please read Security below. For information about how we use and secure registered users’ information as well as your rights and responsibilities regarding the use of these features, please read our Application Privacy Policy which is incorporated by reference into this Agreement.

            <h4> Use License: </h4>
            No permission is granted to temporarily or permanently download or use or make any copy of the materials (information or software) on Sure Cares’ Application for personal or any other form of usage. By accessing the information on the Application, you agree to not:
            1. Modify or copy the materials;
            2. use the materials for any commercial purpose or for any public display (commercial or non-commercial);
            3. attempt to decompile or reverse engineer any software contained on Sure Cares’ Application and website;
            4. remove any copyright or other proprietary notations from the materials; or
            5. transfer the materials to another person or “mirror” the materials on any other server.

            <h4> Membership:</h4>
            <ol>
                <li>Sure Care Application is the flagship healthcare Application in Bangladesh.</li>
                <li>Sure Care Application is the property of Sure Care Organization and will be the custodian of all information pertaining to Sure Care including the member details.</li>
                <li>Sure Care Application is a Health Care Program and it covers all the Health Issues.</li>
                <li>Sure Care will continue until terminated, suspended, modified or converted to other programs by Sure Care authority.</li>
                <li>Sure Care may, at its sole discretion and without any prior intimation, either add or discontinue any Health Centres/ Stores from the Sure Care from time to time.</li>
                <li>The Sure Care Program is open to citizens of Bangladesh above 18 years of age with a valid Bangladeshi mobile number only.</li>
                <li>Any individual who has a valid and active Bangladeshi mobile number and has registered or transacted at Sure Care is eligible for membership. Presently, no enrolment fee is applicable for membership of the Sure Care Application.</li>
                <li>Members will be auto-enrolled into the program on fulfilling the eligibility threshold (minimum spend). Every member has the option to opt-out of the program upon enrolment.</li>
                <li>The membership is valid subject to the member adhering to the Terms and Conditions of the Sure Care Program as published from time to time.</li>
                <li> Sure Care Membership is non-transferable.</li>
                <li>On consent, the member hereby agrees to receive all Marketing/ Promotional/ Transaction related communication from Sure Care Organizations and its affiliates through multiple modes of communication channels such as Email, SMS and others.</li>
                <li>As a precondition to becoming a member, a customer will be required to provide mandatory information including full name, valid & active Bangladeshi mobile number and adhere to such terms and conditions as may be prescribed for membership from time to time.</li>
                <li>The Sure Care membership will be issued solely at the discretion of the management and the final discretion on all matters relating to the membership shall rest with Sure Care.</li>
                <li>Sure Care members will not be entitled to any of the benefits under the Sure Care Program for any transaction of the nature of institutional sales.</li>
                <li>Sure Care membership is for individuals only and is not a corporate membership.</li>
                <li>Sure Care membership can be revoked or refused if a member is involved in any act of fraud, shoplifting, cheating with or without cause and without notice.</li>
                <li>Sure Care reserves the right to add, alter, amend and revise terms and conditions as well as rules and regulations governing the Sure Care membership without prior notice.</li>
            </ol>
            In case Sure Care Membership details are lost or misused by the Member, Sure Care shall not be responsible or liable for any misuse of such details.
            <h3>Payments:</h3>
            <ol>
                <li>Sure Care shall solely handle all financial transactions against the Services.</li>
                <li>No payments for the Services will be made other than on Sure Care’s payment gateway.</li>
                <li>Where there is consultation fee, the Patients must pay the consultation fee and VAT before joining the doctor queue for consultation.</li>
                <li>Where there is no consultation fee, the Patients will not be required to make any payment and they will be directly transferred to the doctor consultation queue.</li>
                <li>Once the Patient has made the payment and joined the queue, s/he will not be able to cancel the payment except as provided herein.</li>
                <li>If the payment is successful, you will get a payment confirmation via text message/email.</li>
            </ol>
            <h3>Refund and cancellation:</h3>
            <ol>
                <li>If the wait time in the queue is more than 60 minutes, customers can cancel the consultation and a refund will be made deducting a small payment processing fee.</li>
                <li>If a doctor can not consult a Patient due to any reason within 60 minutes of Initiation Request, the user will get a full refund.</li>
                <li>If a Patient cancels a consultation prior to the Session or video call of the Doctor after acceptance by the Doctor of the Patient’s request before the expiry of 60 minutes from the Initiation Request, a 5% fee will be deducted and the remaining fee will be refunded to the Patient. There may be additional charges to make the refund. For example, if the user wants the money to be refunded to bKash or mobile banking system, bKash transaction fee will be deducted.</li>
                <li>For any cancellation and refund please email to: <b><i><u>refund@surecare.com</u></i></b></li>
                <li>Refund process may take up to 30 working days.</li>
            </ol>
            <h3>Declaration:</h3>
            I fully read and understand the above policy in connection with online payment of fees. I shall abide by the terms and conditions in force or modified from time to time pertaining to the payment.<br />
            <b><i> I accept all Terms and Conditions.</i></b>


        </div>
    );
}