import React, { useState } from 'react';
import SureCare from './Resources/Image/SureCare.png';
import FitnessTracking from './Resources/Image/fitnessTracking.png';
import WellnessGuide from './Resources/Image/WellnessGuide.png';
import MedicalHistoryRecord from './Resources/Image/MedicalHistoryRecord.png'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid } from 'semantic-ui-react';
import { BiSupport, BiRun } from 'react-icons/bi';
import { GiNotebook } from 'react-icons/gi';
import { FaClipboardList } from 'react-icons/fa';

const About = () => {

    const [showImage, setShowImage] = useState(SureCare)



    return (
        <div className='restContainer'>
            <Grid doubling columns={2} className='gridContainer'>
                <Grid.Column stretched>
                    <img src={showImage} alt='Why Choose SureCare' className='chooseImg ' />
                </Grid.Column>
                <Grid.Column>
                    <div className="chooseText">

                        <h1> <b>Why Choose SureCare</b> </h1>

                        <div>
                            <div className='chooseCard '
                                onMouseEnter={() => setShowImage(FitnessTracking)}
                                onMouseLeave={() => setShowImage(SureCare)}
                            >
                                <span className='icons'>
                                <BiRun size="40px" color='#c9116d' />
                                </span>
                                <span>
                                    <h2>Fitness Tracking</h2>
                                    <p>Sure Care Fitness Tracking will keep track of your fitness. It will make your physical activity more organized. Fitness tracking options will provide information on various issues related to physical fitness.</p>
                                </span>
                            </div>

                            <div className='chooseCard '
                                onMouseEnter={() => setShowImage(WellnessGuide)}
                                onMouseLeave={() => setShowImage(SureCare)}
                            >
                                <span className='icons'>
                                    <GiNotebook size="40px" color='#c9116d' />
                                </span>
                                <span>
                                    <h2>Wellness Guide</h2>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to .</p>
                                </span>
                            </div>

                            <div className='chooseCard'
                                onMouseEnter={() => setShowImage(MedicalHistoryRecord)}
                                onMouseLeave={() => setShowImage(SureCare)}>
                                <span className='icons'>
                                    <FaClipboardList size="40px" color='#c9116d' />
                                </span>
                                <span>
                                    <h2>Medical History Record</h2>
                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to </p>
                                </span>
                            </div>
                        </div>
                    </div>
                </Grid.Column>
            </Grid>
            <div className='chooseRow'>



            </div>
        </div>

    );
};

export default About;